import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Grid } from "@mui/material";
import Logo from "../../images/logo.png";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import useGetTables from "../../api/useGetTables";
import "./Splash.css";
const Splash = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const  tables = useGetTables();
  console.log(tables?.data?.data?.data,'tablestables')
  function getQueryStringParams(query) {
    return query.replace(/(^\?)/,'').split("&").reduce((result, item) => {
        let parts = item.split('=');
        result[parts[0]] = decodeURIComponent(parts[1]);
        return result;
    }, {}); 
  }
  const [foundTable,setfoundTable]=useState(null)
  let params = getQueryStringParams(window.location.search);

  const area = params.area;
  const floor = params.floor;

  async function findTableId() {
  
  
      
       console.log(area)
       console.log(floor)
       localStorage.setItem('area',area)
       localStorage.setItem('floor',floor)
       console.log(tables?.data?.data?.data)
       setfoundTable( tables?.data?.data?.data.find(table => table?.table_number == area && table?.floor_name?.en == `floor ${floor}`))
  
        if (foundTable) {
            console.log(`The ID of the table is: ${foundTable.id}`);
            localStorage.setItem('tableId',foundTable.id)
        } else {
            console.log("No table found that matches the criteria.");
        }
   
  }
  useEffect(()=>{
    findTableId()
  },[foundTable,params])
  const [animationStep, setAnimationStep] = useState(0);
  const [clickedButton, setClickedButton] = useState(null);

  useEffect(() => {
    // Start the logo and text animations once the component is mounted
    setAnimationStep(1);

    if (animationStep === 4) {
      setTimeout(() => {
        navigate("/home"); 
      }, 1000); // Navigate after the final animation step
    }
     
    
    
    // Navigate after the final animation step
  }, [animationStep, navigate]);

  const handleLanguageClick = (language, button) => {
    setClickedButton(button);
    
    setAnimationStep(4)
    setTimeout(() => {
      i18n.changeLanguage(language);
    });
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        backgroundColor:
          animationStep === 4 ? "#000" : animationStep > 0 ? "#d8d8d8" : "#fff",
        transition: "background-color 6s ease",
      }}
      className={animationStep === 4 ? "darken-bg" : ""}
    >
      <img
        src={Logo}
        alt="artista menu"
        className={`logo-animation ${animationStep === 1 ? "logo-scale" : ""}`}
        style={{
          width: "200px",
          transform:
            animationStep >= 1 ? "scale(1.3)" : "scale(0)",
          transition: "transform 2s ease",
          marginTop: "50px",
        }}
      />
      <Box
        sx={{
          marginTop: "100px",
          display: "flex",
          justifyContent: "center",
          
          width: "80%",
          marginBottom:'100px'
        }}
      >
        <Typography
          className={`ltr-animation ${
            animationStep >= 1 ? "text-slide-in-left" : ""
          }`}
          sx={{
            textAlign: "center",
            textTransform: "capitalize",
            mt: 1,
            fontWeight: "600",
            fontSize: "25px",
            width: "43%",
          }}
        >
          Welcome to artista clinic
         
          
        </Typography>
        <Typography
          className={`rtl-animation ${
            animationStep >= 1 ? "text-slide-in-right" : ""
          }`}
          sx={{
            textAlign: "center",
            textTransform: "capitalize",
            mt: 1,
            fontWeight: "600",
            fontSize: "25px",
            direction: "rtl",
            width: "57%",
          }}
        >
          مرحبًا بك في عيادة أرتيستا <br />
        </Typography>
      </Box>
      <Grid
        container
        spacing={2}
        sx={{
          mt: 4,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
      <Grid              className={`  language-box2`}
      sx={{ marginX:"20px"}}
>
        <Button
      
          className={` ${
            clickedButton === "en" ? "final-scale-brightness" : "shrink"
          }`}
          sx={{
            width: "150px",
            height: "60px",
            fontSize: "18px",
           
            fontWeight: "bold",
            opacity: animationStep === 5 && clickedButton === "ar" ? 0 : 1,
            
            transition: animationStep === 1 ? "transform 3s ease" : "",
          }}
          variant="contained"
          onClick={() => handleLanguageClick("en", "en")}
        >
          English
        </Button>
      </Grid>
        <Grid              className={` language-box2`}
>
          <Button
            className={`   ${
              clickedButton === "ar" ? "final-scale-brightness" : "shrink "
            }`}
            sx={{
              width: "150px",
              height: "60px",
              fontSize: "18px",
              fontWeight: "bold",
              opacity: animationStep === 5 && clickedButton === "en" ? 0 : 1,
                      }}
            variant="contained"
            onClick={() => handleLanguageClick("ar", "ar")}
          >
            العربية
          </Button>
        </Grid>
       
       
      </Grid>
    </Box>
  );
};

export default Splash;
