import axios from "axios";
// const baseURL = 'http://127.0.0.1:8000/api'
const baseURL = "https://api.dentistmenu.gomaplus.tech/api";
const client = axios.create({ baseURL });
export const request = async ({ ...options }) => {
  client.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    "dentest-menu-token"
  )}`;
  return client(options).then((res) => res);
};
