import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { motion, useAnimationControls } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import logo from "../images/logo.png";

const MotionBox = motion(Box);

const SecondartHomePage = () => {
  const controls = useAnimationControls();
  const theme = useTheme();
  const { t } = useTranslation();

  useEffect(() => {
    controls.start("inView");
  }, [controls]);

  const navigate = useNavigate();

  const handleClickRate = async () => {
    await controls.start({
      x: 500,
      opacity: 0,
      transition: { duration: 0.5 },
    });
    
    // navigate("/categories");
    window.location.href = `https://user.super-rate.tech/?business=32a080e5-662c-4ed5-92de-c54bab25959a&floor=${localStorage.getItem('floor')}&area=${localStorage.getItem('area')}`;

  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        px: 5,
      }}
    >
      {/* Centered Logo at the Top */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mb: 5,
          marginBottom: "120px",
        }}
      >
        <img
          src={logo}
          alt={t("secondart_home_page.alt_text")}
          style={{
            width: "160px", // Smaller logo size
            marginBottom: "10px",
          }}
        />
        <Typography variant="h6" textAlign="center">
          {t("secondart_home_page.centered_text")}
        </Typography>
      </Box>

      {/* Split Content Below */}
      <MotionBox
        variants={{
          initial: {
            x: -100,
            opacity: 0,
          },
          inView: { x: 0, opacity: 1 },
          exit: {
            width: "100%",
          },
        }}
        initial={"initial"}
        animate={controls}
        transition={{
          delay: 0.5,
        }}
        sx={{
          display: "flex",
          width: "100%",
          maxWidth: "1000px",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: { xs: "column", md: "row" }, // Stack vertically on small screens, horizontally on medium and up
          borderRadius: "12px",
          mx: "auto",
          mt: 5, // Added margin-top to move sections towards the center of the screen
        }}
      >
        {/* Left Side */}
        <Box
          sx={{
            width: { xs: "100%", md: "50%" },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingRight: { md: 2 },
            marginBottom: { xs: 3, md: 0 }, // Add spacing between the stacked items on small screens
          }}
        >
          <Typography fontWeight="bold" textAlign="center">
            {t("secondart_home_page.rate_us_message")}
          </Typography>

          <Button
            sx={{
              mx: "auto",
              mt: 3,
              width: "60%",
              maxWidth: "300px",
            }}
            onClick={handleClickRate}
            variant="outlined"
          >
            {t("secondart_home_page.rate_us_button")}
          </Button>
        </Box>

        {/* Right Side */}
        <Box
          sx={{
            width: { xs: "100%", md: "50%" },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingLeft: { md: 2 },
          }}
        >
          <Typography textAlign="center" fontWeight="bold">
            {t("secondart_home_page.welcome_message")}
          </Typography>

          <Button
            sx={{
              width: "60%",
              maxWidth: "300px",
              mx: "auto",
              mt: 3,
            }}
            component={Link}
            to={"/categories/1"}
            variant="contained"
          >
            {t("secondart_home_page.browse_menu_button")}
          </Button>
        </Box>
      </MotionBox>
    </Box>
  );
};

export default SecondartHomePage;
