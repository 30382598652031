import { alpha, Box, Container, Typography } from "@mui/material";
import React from "react";
import { Outlet } from "react-router";
import Cart from "../Cart";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";
import { Link } from "react-router-dom";

const MainLayout = () => {
  const { t } = useTranslation();
  return (
    <Container fixed>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "60px",
          justifyContent: "space-between",
          mb: 2,
          backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.08),
          mt: 2,
          borderRadius: "15px",
          px: 1,
          position: "sticky",
          top: "10px",
          backdropFilter: "blur(20px)",
          zIndex: (theme) => theme.zIndex.appBar,
        }}
      >
        <Typography component={Link} sx={{textDecoration: 'none'}} to={'/home'} color={'primary.main'} textTransform={'capitalize'} variant="h6">{t("splash.name")}</Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Cart />
          <LanguageSwitcher />
        </Box>
      </Box>
      <Outlet />
    </Container>
  );
};

export default MainLayout;
