import { configureStore } from "@reduxjs/toolkit";
import cartSlise from "./slices/cartSlise";

const store = configureStore({
  reducer: {
    cart: cartSlise,
  },
});


export default store