import { MenuItem, Select } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const LanguageSwitcher = () => {
  const { t, i18n } = useTranslation();
  return (
    <Select
      onChange={(e) => {
        i18n.changeLanguage(e.target.value);
      }}
      value={i18n.language}
      sx={{
        height : '30px'
      }}
    >
      <MenuItem value="ar">{t("languages.en")}</MenuItem>
      <MenuItem value="en">{t("languages.ar")}</MenuItem>
    </Select>
  );
};

export default LanguageSwitcher;
