import { DoneOutlined } from "@mui/icons-material";
import { alpha, Box, Tooltip, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const IngerdiwntList = ({
  optionalIngredients,
  setSelectedOptionalIngredient,
  selectedOptionalIngredient,
}) => {
  const { i18n } = useTranslation();

  const handleIngredientClick = (ingredientId) => {
    if (selectedOptionalIngredient.includes(ingredientId)) {
      // Prevent deselecting the last remaining ingredient
      if (selectedOptionalIngredient.length === 1) return;
      setSelectedOptionalIngredient(
        selectedOptionalIngredient.filter((id) => id !== ingredientId)
      );
    } else {
      setSelectedOptionalIngredient([ingredientId]); // Set the new selection directly
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        gap: "5px",
        flexWrap: "wrap",
      }}
    >
      {optionalIngredients.map((optionalIngredient) => (
        <Tooltip key={optionalIngredient.id} title={optionalIngredient.price}>
          <Typography
            sx={{
              backgroundColor: (theme) =>
                selectedOptionalIngredient.includes(optionalIngredient.id)
                  ? alpha(theme.palette.success.main, 0.5)
                  : alpha(theme.palette.primary.main, 0.1),
              p: 0.5,
              borderRadius: "20px",
              display: "flex",
              alignItems: "center",
              gap: "2px",
              cursor: "pointer",
              transition: "0.2s",
            }}
            onClick={() => handleIngredientClick(optionalIngredient.id)}
          >
            {selectedOptionalIngredient.includes(optionalIngredient.id) && (
              <DoneOutlined color="success" />
            )}
            {optionalIngredient.name[i18n.language]}
          </Typography>
        </Tooltip>
      ))}
    </Box>
  );
};

export default IngerdiwntList;
