import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import "./App.css";
import { AppRoutes } from "./routes";
import "./i18n";
import { useTranslation } from "react-i18next";
import Rtl from "./layout/RTL/RTL";
import { useEffect } from "react";

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    document.dir = i18n.language === "en" ? "ltr" : "rtl";
  }, [i18n.language]);

  const theme = createTheme({
    direction: i18n.language === "en" ? "ltr" : "rtl",
    palette: {
      mode: "light",
      primary: {
        main: "#c87f84",
      },
      components: {
        MuiSnackbarContent: {
          styleOverrides: {
            root: {
              // Style all snackbars
            },
            info: {
              // Targeting only 'info' variant
              color: '#000', // Black text color
            }
          }
        }
      }
    },

    typography: {
      fontFamily:
        i18n.language === "ar"
          ? '"Alexandria", sans-serif'
          : '"Montserrat", sans-serif',
    },

    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "capitalize",
          },
        },
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Rtl>
        <AppRoutes />
      </Rtl>
    </ThemeProvider>
  );
}

export default App;
