import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { request } from "./request";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { CLEAR_CART } from "../store/slices/cartSlise";
import React from "react";
const useMakeAnOrder = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const naviaget = useNavigate()
  const dispatch = useDispatch()

  const makeAnOrderRequest = (data) => {
    return request({
      url: "/orders",
      method: "post",
      data,
    });
  };

  const mutation = useMutation({
    mutationKey: ["make-an-order"],
    mutationFn: makeAnOrderRequest,

    onSuccess: () => {
      enqueueSnackbar(t("make_an_order.success"), {
        variant: "info",
      });
      naviaget(`/?floor=${localStorage.getItem('floor')}&area=${localStorage.getItem('area')}`)
      dispatch(CLEAR_CART())
    },
    onError: () => {
      enqueueSnackbar(t("make_an_order.error"), {
        variant: "error",
      });
    },
  });

  return mutation;
};

export default useMakeAnOrder;
