import { Box, CircularProgress } from "@mui/material";
import React from "react";
import ProductCard from "./components/ProductCard";
import useGetProductOfCategory from "../../api/useGetProductOfCategory";
import useGetTables from "../../api/useGetTables";


const ProductOfCategory = () => {
  const productsData = useGetProductOfCategory();
  if (productsData.isLoading) {
    return (
      <Box
        sx={{
          height: "calc(100vh - 100px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }

  console.log(productsData?.data?.data?.data)
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "stretch",
        columnGap: "10px",
        flexWrap: "wrap",
        rowGap: "20px",
        mb: 2,
      }}
    >
      {productsData?.data?.data?.data?.map((product) => (
        <ProductCard key={product.id} product={product} />
      ))}
    </Box>
  );
};

export default ProductOfCategory;
