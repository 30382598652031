import {
  DeleteForever,
  DoneOutlined,
  SendRounded,
  ShoppingCartOutlined,
} from "@mui/icons-material";
import {
  alpha,
  Autocomplete,
  Avatar,
  Badge,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormHelperText,
  IconButton,
  Menu,
  Slide,
  styled,
  TextField,
  Tooltip,
  tooltipClasses,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FadeIn from "../../components/FadeIn/FadeIn";
import { DELETE_CART_OBJECT } from "../../store/slices/cartSlise";
import useMakeAnOrder from "../../api/useMakeAnOrder";
import { Formik } from "formik";
import * as yup from "yup";
import useGetTables from "../../api/useGetTables";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import './cart.css'
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const Cart = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const [tableOpen, setTableOpen] = React.useState(false);
  const { products } = useSelector((state) => state.cart);
  const sendAnOrder = useMakeAnOrder();
  const tables = useGetTables();

  const [dOpen, setDOpen] = React.useState(false);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    if (products?.length > 0) {
      setIsAnimating(true);
      const timer = setTimeout(() => setIsAnimating(false), 3000);
      return () => clearTimeout(timer);
    }
  }, [products?.length]);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickDialogOpen = () => {
    setDOpen(true);
  };

  const handleDialogClose = () => {
    setDOpen(false);
  };



  return (
    <React.Fragment>
      <HtmlTooltip
      style={{
        animation: isAnimating
          ? "scaleCart 3s ease-in-out, changeBorderColor 3s ease-in-out"
          : "none",
      }}
        title={
          <>
            <em>
              you can make your order dirctly from here by clickng in send order
            </em>
            <Button fullWidth variant="contained">
              send order
            </Button>
          </>
        }
        >
        <Badge
          badgeContent={products.length || 0}
          color="primary"
          className={isAnimating ? "animate-cart" : ""}
          
        >
          <Button
            color="primary"
            variant="outlined"
            onClick={handleClick}
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            endIcon={<ShoppingCartOutlined  />}
            
          >
            {t("buttons.send_order")}
          </Button>
        </Badge>
      </HtmlTooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              minWidth: "300px",
              overflow: "visible",
              backgroundColor: (theme) =>
                alpha(theme.palette.primary.main, 0.1),
              mt: 1.5,
              borderRadius: "6px",
              maxHeight: "600px",
              overflowY: "auto",
              // position : 'relative',
              px: 2,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              backdropFilter: "blur(20px)",
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {products.length === 0 && (
          <Typography
            sx={{
              fontWeight: "bold",
              textAlign: "center",
              fontSize: "18px",
              maxWidth  :'200px',
              mx : 'auto'
            }}
          >
            {t("cart.empty")}
          </Typography>
        )}
        {products.map((ele, i) => (
          <FadeIn key={i}>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "start",
                  gap: "5px",
                  my: 3,
                }}
              >
                <Box
                  sx={{
                    width: "50px",
                  }}
                >
                  <Avatar
                    sx={{
                      width: "100px",
                      height: "100px",
                    }}
                    src={ele.product.image}
                  />
                </Box>
                <Box
                  sx={{
                    width: "calc(100% - 5px - 100px)",
                  }}
                >
                  <Typography>{ele.product.name[i18n.language]}</Typography>
                  <Typography sx={{ maxWidth: "300px", color: "grey.900" }}>
                    {ele.product.description[i18n.language]}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      mb: 2,
                      fontSize: "18px",
                    }}
                  >
                    {t("cart.quantity")} : {ele.quantity}
                  </Typography>
                  {/**     <Typography
                    mb={2}
                    sx={{
                      fontWeight: "bold",
                      mb: 2,
                      fontSize: "18px",
                    }}
                  >
                    {t("cart.price")} : {ele.product.price * ele.quantity}{" "}
                    {t("cart.sp")}
                  </Typography>
                   */}
                  <Box
                    sx={{
                      display: "flex",
                      gap: "5px",
                      flexWrap: "wrap",
                      maxWidth: "300px",
                    }}
                  >
                    {ele.product.optionalIngredients.map(
                      (optionalIngredient) => (
                        <Typography
                          key={optionalIngredient.id}
                          sx={{
                            backgroundColor: (theme) =>
                              ele.selectedOptionalIngredient.includes(
                                optionalIngredient.id
                              )
                                ? alpha(theme.palette.success.main, 0.5)
                                : alpha(theme.palette.primary.main, 0.1),
                            p: 0.5,
                            borderRadius: "20px",
                            display: "flex",
                            alignItems: "center",
                            gap: "2px",
                            cursor: "pointer",
                            transition: "0.2s",
                          }}
                          onClick={() => {}}
                        >
                          {ele.selectedOptionalIngredient.includes(
                            optionalIngredient.id
                          ) && <DoneOutlined color="#fff" />}{" "}
                          <Tooltip title={optionalIngredient.price}>
                            {optionalIngredient.name[i18n.language]}
                          </Tooltip>
                        </Typography>
                      )
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "50px",
                  }}
                >
                  <IconButton
                    onClick={() => {
                      dispatch(DELETE_CART_OBJECT(i));
                    }}
                    color="error"
                  >
                    <DeleteForever />
                  </IconButton>
                </Box>
              </Box>
              {products.length - 1 !== i && <Divider />}
            </Box>
          </FadeIn>
        ))}
        {products.length !== 0 && (
          <Box
            sx={{
              px: 1,
              py: 3,
              borderRadius: "10px",
              position: "sticky",
              bottom: "0px",
            }}
          >
            <Button
              onClick={() => {
                handleClose();
                handleClickDialogOpen();
              }}
              fullWidth
              color="info"
              variant="contained"
            >
              {t("buttons.send_order")}
            </Button>
          </Box>
        )}
      </Menu>
      {/* <IconButton
        onClick={() => {
          handleClose();
          handleClickDialogOpen();
        }}
        fullWidth
        color="primary"
        variant="outlined"
        sx={{}}
      >
        <SendRounded />
      </IconButton> */}
      <Dialog
        open={dOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDialogClose}
      >
        <DialogTitle>{t("cart.dialog.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText mb={2}>
            {t("cart.dialog.contentText")}
          </DialogContentText>
          <Formik
            initialValues={{
              table_id: localStorage.getItem("tableId"), // Default value for the form
            }}
            onSubmit={async (values) => {
              const order_items = products.map((product) => {
                return {
                  meal_id: product.product.id,
                  quantity: product.quantity,
                  optionalIngredient: product.selectedOptionalIngredient,
                };
              });

              // Setting the table_id directly from localStorage when sending the order
              const tableId = localStorage.getItem("tableId");

              await sendAnOrder.mutateAsync({
                order_items,
                table_id: tableId,
              });

              handleDialogClose();
            }}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <LoadingButton
                  variant="contained"
                  fullWidth
                  loading={sendAnOrder.isPending}
                  type="submit"
                  sx={{ my: 2 }}
                >
                  {t("buttons.send_order")}
                </LoadingButton>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default Cart;
