import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

const CategoryCard = ({ category }) => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  return (
    <Card
      onClick={() => {
        navigate(`/categories/${category.id}`);
      }}
      sx={{
        cursor: "pointer",
        width: {
          xs: "100%",
          sm: "calc(50% - 10px)",
          md: "calc(33.333% - 10px)",
          lg: "calc(25% - 10px)",
        },
        backgroundColor: "transparent",
        backdropFilter: "blur(10px)",
      }}
    >
      <CardActionArea
        sx={
          {
            // backgroundColor : 'transparent'
          }
        }
      >
        <CardMedia
          component="img"
          height="140"
          sx={{
            objectFit :'contain'
          }}
          image={category.image}
          alt={category.name.en}
        />
        <CardContent
          sx={
            {
              // backgroundColor : 'transparent'
            }
          }
        >
          <Typography gutterBottom variant="h5" component="div">
            {category.name[i18n.language]}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CategoryCard;
