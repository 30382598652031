import { useQuery } from "@tanstack/react-query"
import { request } from "./request"
import { useParams } from "react-router"

const useGetProductOfCategory = () => {
    const {categoryID} = useParams()
    const getProductOfCategroiesRequest = () => {
        return request({
            url : `/getMealByCategoryId/${categoryID}`
        })
    }

    const mutation = useQuery({
        queryKey : [`get-product-if-${categoryID}`],
        queryFn : getProductOfCategroiesRequest
    })

    return mutation
}

export default useGetProductOfCategory
