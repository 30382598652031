import { useQuery } from "@tanstack/react-query";
import { request } from "./request";

const useGetTables = () => {
  const getTablesRequest = () => {
    return request({
      url: "/tables",
    });
  };

  const query = useQuery({
    queryKey: ["get-tables"],
    queryFn: getTablesRequest,
  });

  return query;
};

export default useGetTables;
