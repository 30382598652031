import { useRoutes } from "react-router";
import CategoryPage from "../views/Category";
import MainLayout from "../layout/mainLayout";
import ProductOfCategory from "../views/ProductOfCategory/ProductOfCategory";
import Splash from "../views/Splash";
import SecondartHomePage from "../views/SecondartHomePage";

export const AppRoutes = () => {
  return useRoutes([
    {
      path: "",
      children: [
        {
          path: "",
          element: <Splash />,
        },
      ],
    },
    {
      path : 'home',
      element : <SecondartHomePage />
    },
    {
      path: "categories",
      element: <MainLayout />,
      children: [
        {
          path: "",
          element: <CategoryPage />,
        },
        {
          path: ":categoryID",
          element: <ProductOfCategory />,
        },
      ],
    },
  ]);
};
