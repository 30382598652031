import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";

import { MaterialDesignContent } from "notistack";
import { alpha, styled } from "@mui/material";
import { Provider } from "react-redux";
import store from "./store";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const StyledMaterialDesignContent = styled(MaterialDesignContent)(
  ({ theme }) => ({
    "&.notistack-MuiContent-info": {
      backgroundColor: alpha(theme.palette.info.main, 0.2),
      backdropFilter: "blur(10px)",
      color : '#000'
    },
  })
);

const client = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <QueryClientProvider client={client}>
      <SnackbarProvider
        maxSnack={1}
        iconVariant={{
          success: "✅",
          error: "✖️",
          warning: "⚠️",
          info: "✅ ",
        }}
        Components={{
          default: StyledMaterialDesignContent,
          info: StyledMaterialDesignContent,
        }}
        autoHideDuration={3000}
        TransitionProps={{
          direction: "down",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Provider store={store}>
          <App />
        </Provider>
      </SnackbarProvider>
    </QueryClientProvider>
  </BrowserRouter>
);
