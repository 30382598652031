import { useQuery } from "@tanstack/react-query"
import { request } from "./request"

const useGetCategroirs = () => {
    const getCategroiesRequest = () => {
        return request({
            url : '/categories'
        })
    }

    const mutation = useQuery({
        queryKey : ['get-categories'],
        queryFn : getCategroiesRequest
    })

    return mutation
}

export default useGetCategroirs