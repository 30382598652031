import { AddOutlined, RemoveOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import IngerdiwntList from "./IngerdiwntList";
import { useDispatch } from "react-redux";
import { ADD_TO_CART } from "../../../store/slices/cartSlise";
import { useTranslation } from "react-i18next";

const ProductCard = ({ product }) => {
  const [count, setCount] = useState(1);
  const [selectedOptionalIngredient, setSelectedOptionalIngredient] = useState(
    product.optionalIngredients.length ? [product.optionalIngredients[0].id] : []
  );
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const addToCardHandler = () => {
    // Ensure at least one ingredient is selected if there are optional ingredients
    if (product.optionalIngredients.length && selectedOptionalIngredient.length === 0) {
      enqueueSnackbar(t("product_card.select_ingredient"), { variant: "warning" });
      return;
    }

    dispatch(
      ADD_TO_CART({
        product: product,
        quantity: count,
        selectedOptionalIngredient,
      })
    );
    setCount(1);
    setSelectedOptionalIngredient(
      product.optionalIngredients.length ? [product.optionalIngredients[0].id] : []
    );
    enqueueSnackbar(
      t("product_card.notification", {
        mealName: product.name[i18n.language],
      }),
      { variant: "info" }
    );
  };

  return (
    <Card
      sx={{
        cursor: "pointer",
        width: {
          xs: "100%",
          sm: "calc(50% - 10px)",
          md: "calc(33.333% - 10px)",
          lg: "calc(25% - 10px)",
        },
      }}
    >
      <CardMedia
        component="img"
        height="140"
        image={product.image}
        sx={{
          objectFit : 'contain'
        }}
        alt={product.name[i18n.language]}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {product.name[i18n.language]}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {product.description[i18n.language]}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            mb: 1,
          }}
        >
          <IconButton
            onClick={() => {
              if (count === 1) {
                return;
              }
              setCount((prev) => prev - 1);
            }}
            color="secondary"
          >
            <RemoveOutlined />
          </IconButton>
          <Typography color={"primary.main"}>{count}</Typography>
          <IconButton
            onClick={() => {
              setCount((prev) => prev + 1);
            }}
            color="secondary"
          >
            <AddOutlined />
          </IconButton>
        </Box>
        {product.optionalIngredients.length > 0 && (
          <IngerdiwntList
            optionalIngredients={product.optionalIngredients}
            selectedOptionalIngredient={selectedOptionalIngredient}
            setSelectedOptionalIngredient={setSelectedOptionalIngredient}
          />
        )}
        <Button
          onClick={addToCardHandler}
          sx={{ mt: 2 }}
          variant="contained"
          size="small"
        >
          {t("buttons.add_to_cart")}
        </Button>
      </CardContent>
    </Card>
  );
};

export default ProductCard;
