import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import CategoryCard from "./components/CategoryCard";
import useGetCategroirs from "../../api/useGetCategroirs";
import { useTranslation } from "react-i18next";

const categories = [
  {
    id: 1,
    name: {
      ar: "المقبلات",
      en: "Appetizers",
    },
    image: "https://picsum.photos/200?random=1",
  },
  {
    id: 2,
    name: {
      ar: "السلطات",
      en: "Salads",
    },
    image: "https://picsum.photos/200?random=2",
  },
  {
    id: 3,
    name: {
      ar: "الشوربات",
      en: "Soups",
    },
    image: "https://picsum.photos/200?random=3",
  },
  {
    id: 4,
    name: {
      ar: "الأطباق الرئيسية",
      en: "Main Dishes",
    },
    image: "https://picsum.photos/200?random=4",
  },
  {
    id: 5,
    name: {
      ar: "المعكرونة",
      en: "Pasta",
    },
    image: "https://picsum.photos/200?random=5",
  },
  {
    id: 6,
    name: {
      ar: "البيتزا",
      en: "Pizza",
    },
    image: "https://picsum.photos/200?random=6",
  },
  {
    id: 7,
    name: {
      ar: "الحلويات",
      en: "Desserts",
    },
    image: "https://picsum.photos/200?random=7",
  },
  {
    id: 8,
    name: {
      ar: "المشروبات",
      en: "Beverages",
    },
    image: "https://picsum.photos/200?random=8",
  },
  {
    id: 9,
    name: {
      ar: "الأطباق الجانبية",
      en: "Side Dishes",
    },
    image: "https://picsum.photos/200?random=9",
  },
  {
    id: 10,
    name: {
      ar: "الوجبات السريعة",
      en: "Fast Food",
    },
    image: "https://picsum.photos/200?random=10",
  },
];

const CategoryPage = () => {
  const categoriesData = useGetCategroirs();
  const {t} = useTranslation()
  if (categories.isLoading) {
    return (
      <Box
        sx={{
          height: "calc(100vh - 100px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "stretch",
        columnGap: "10px",
        flexWrap: "wrap",
        rowGap: "20px",
        mb: 2,
      }}
    >
      {
        categoriesData?.data?.data?.data?.length === 0 && <Typography color={'primary.main'} textAlign={'center'}>{t('category.no_data')}</Typography>
      }
      {categoriesData?.data?.data?.data?.map((category) => (
        <CategoryCard key={category.id} category={category} />
      ))}
    </Box>
  );
};

export default CategoryPage;
