import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  products: [],
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    ADD_TO_CART: (state, action) => {
      state.products.push(action.payload);
    },
    DELETE_CART_OBJECT: (state, action) => {
      state.products = state.products.filter((ele, i) => i !== action.payload);
    },
    CLEAR_CART : (state , action) => {
      state.products = []
    }
  },
});

export default cartSlice.reducer;

export const { ADD_TO_CART, DELETE_CART_OBJECT , CLEAR_CART } = cartSlice.actions;
